const Menu = [
  {
    heading: "menu",
    route: "/trainer",
    pages: [
      {
        heading: "Add Trainer",
        route: "/trainer/add-trainer",
        fontIcon: "fa-user",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Trainer List",
        route: "/trainer/trainer-list",
        fontIcon: "fa-user",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      // {
      //   heading: "Inactive Trainer List",
      //   route: "/trainer/inactive-trainer-list",
      //   fontIcon: "fa-user",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
    ],
  },
];

export default Menu;
